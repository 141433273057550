// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../link'
import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section className="section" id="landing">
    <div className="container">
      <div>
        <h1>Digital Media Initiatives</h1>
        <h2>
          <span>technology</span>
          <span>+ design</span>
          <span>+ publishing</span>
          <span>+ accessibility</span>
          <span>+ education</span>
          <span>+ social venture</span>
        </h2>
      </div>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={0} sm={0} md={6} lg={8} xl={6} xxl={6}>
          <p className="lead">
            Digital Media Initiatives is a small and good-tech project from
            India
          </p>
        </Col>
        <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}>
          &nbsp;
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            For the last two decades, we have worked in traditional print
            publishing, digital e-publishing, and accessible publishing.
            Recently we have ventured into education technology, social and
            ecological projects.
          </p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            We work with many initiatives by public and private actors, and we
            offer our expertise through services, technology platforms, and
            long-term collaborative and co-creative partnerships.
          </p>
        </Col>
      </Row>
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
