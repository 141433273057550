// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import chunk from 'lodash/chunk'
import sumBy from 'lodash/sumBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Gallery */
const Gallery = ({ images, targetRowHeight }) => {
  const tracks = chunk(images, 9)

  return (
    <div className="gallery flow">
      {map(tracks, (track) => (
        <div className="track">
          {map(track, (image) => {
            const {
              childImageSharp: {
                gatsbyImageData: { width, height },
              },
            } = image
            const ar = width / height
            const colWidth = `${(100 / 9) * ar}%`

            return (
              <GatsbyImage
                style={{ width: colWidth }}
                image={getImage(image)}
              />
            )
          })}
        </div>
      ))}
    </div>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Gallery
