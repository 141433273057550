// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../link'
import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section id="summary">
    <div className="container">
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={0} sm={0} md={5} lg={5} xl={5} xxl={5}>
          &nbsp;
        </Col>
        <Col xs={0} sm={0} md={5} lg={5} xl={5} xxl={5}>
          &nbsp;
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
          <div className="has-copy">
            <div>
              <h2>
                <span>technology</span>
                <span>expertise</span>
                <strong>We offer</strong>
              </h2>
              <p>
                <strong>Digital Publishing:</strong>&nbsp;We offer end-to-end
                digital e-publishing services including conversion of
                manuscripts into e-books, support with distribution across
                global trade networks, management of ONIX data, Digital Rights
                Management and a modern retail platform for indie publishers.
              </p>
              <p>
                <strong>Accessible Publishing:</strong>&nbsp;We offer a broad
                range of accessibility remediation services to publishers for
                print and digital assets, including NIMAS conversion (with
                MathML 3.0), Tagged PDF/UA remediation, and Google Docs and
                Slides as per the Web Content Accessibility Guidelines (WCAG)
                2.x and Section 508 specifications to make your documents or
                presentations more accessible.
              </p>
              <p>
                <strong>Education Technologies:</strong>&nbsp;We offer a
                Competency-Based Teaching-Learning Platform that can enable
                exceptional pedagogy and didactics. We also support and work
                closely with academic institutions and publishers to create
                digital instructional materials that make learning personalized
                and deeper.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={5} md={5} lg={5} xl={5} xxl={5}>
          &nbsp;
        </Col>
      </Row>
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
