// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import Link from '../../link'
// import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const testimonials = [
  {
    quote1:
      'We have benefited from your expertise and professionalism, and we look forward to a long relationship.',
    quote2: null,
    quote3: null,
    cite: 'Russel White',
    origin: 'Random House',
  },
  {
    quote1: 'I enjoyed working with DMI.',
    quote2:
      'I was surprised to meet such an efficient and open minded company.',
    quote3:
      'The DMI final product was professional and exceeded my expectations.',
    cite: 'Shabtai Shoval',
    origin: 'Scientific Driven Systems',
  },
  {
    quote1:
      'I am a new author. Having chosen to self-publish, I interacted with many different professionals (twenty or more). DMI is the one that I will return to again and again.',
    quote2:
      'They were responsive, sensitive to my schedule and the price was competetive.',
    quote3:
      'My files were uploaded to the eBook channels without any problems. Thanks DMI!',
    cite: 'Betty Hearn',
    origin: 'Rhetor House Press',
  },
  {
    quote1:
      'DMI does an excellent job at converting our titles to ebook formats.',
    quote2: 'They are fast, accurate and trustworthy.',
    quote3:
      'I find their dashboard service very convenient as well. It allows you to sign-in and upload new titles for conversion and then check and approve them afterwards. I highly reccomend working with them.',
    cite: 'Dr Nicole Edmondson, Managing Editor',
    origin: 'University College London IOE Press',
  },
  {
    quote1:
      'For the past 5 years, we at DKOEbooks have had an excellent experience working with DMI.',
    quote2:
      'We have been accorded nothing less than a highly professional service.',
    quote3:
      'Together we are fulfilling a kingdom mission to reach the unreachable with the gospel…',
    cite: 'Anthony Akerele, Managing Editor',
    origin: 'Mountain of Fire and Miracle Ministries',
  },
  {
    quote1:
      'We have been using DMI for many years to create NIMAC ciles for our products. DMI has always been very timely in responding to our needs for NIMAS file creation and the files have always passed the NIMAC validation process before being accepted into the repository.',
    quote2:
      'DMI is excellent to work with and they have always met the deadlines or exceeded them.',
    quote3:
      'I highly recommend DMI based on their quality, cost and completion of work.',
    cite: 'Randy K. Messer',
    origin: 'Perfection Learning',
  },
  {
    quote1:
      'We have been working with DMI since 2011 whenever we have required eBook version of the titles we reissue. DMI is very reliable, speedy and competent — often having to convert old print editions into pristine eBooks, with updates to the text where required.',
    quote2: 'Their technical expertise is second to none.',
    quote3:
      'As part of their service they take endless pains to ensure that the finished product is not only attractive but completely error free. They are a delight to work with.',
    cite: 'Patricia Davidson',
    origin: 'Straw Hat',
  },
  {
    quote1:
      'Our experience with DMI has been positive. Submittied items have all been completed in a timely fashion. It is a very nice feature to have the products automatically sent to NIMAC once the files are prepared.',
    quote2: null,
    quote3: null,
    cite: 'Sheldon Savage',
    origin: 'Studies Weekly',
  },
  {
    quote1: null,
    quote2: 'A brilliant company and a pleasure to have worked with.',
    quote3:
      "I hope we'll be doing more together in the near future. Good luck and thanks to you all!",
    cite: 'Robert Forrest-Webb',
    origin: null,
  },
  {
    quote1:
      "Barcelona Publishers depends exclusively on DMI to meet all of it's digitisation needs. The conversions have been impeccable and reliably done, with careful attention paid to retaining the correct formatting, accurately reproducing all images and figures, and effectively embedding both audio and video files into the eBook…",
    quote2: null,
    quote3: null,
    cite: 'Dr. Kenneth Bruscia, Professor Emeritus (Music Therapy)',
    origin: 'Temple University, USA',
  },
  {
    quote1:
      'It has been my privelage, and joy, to have discovered DMI, right at the inception of their new company.',
    quote2:
      'They have been instrumental in developing contact between my unusual writings, and the outside world.',
    quote3:
      'DMI is a company of rare vision and selflesness — their work is propmpt, courteous, first-rate. I recommend him most highly.',
    cite: 'Junipur',
    origin: 'Mother of Light Publications',
  },
  {
    quote1: 'Many, many thanks!',
    quote2: 'You have been truly wonderful to work with!',
    quote3:
      "I'm telling all my author colleagues about you! Onwards to Nirvana…",
    cite: 'Jo Franklin',
    origin: 'SeaCastle Films',
  },
  {
    quote1:
      'We were happy with the professioanl service we recieved from DMI. We got quick turnaround and a great price.',
    quote2:
      'We are planning on using them in the future for our many new products that will be coming out.',
    quote3: 'We would highly recommend them to anyone.',
    cite: 'Les and Linda Wise',
    origin: 'The Deliberate Musician',
  },
  {
    quote1:
      'After two weeks of trial and error, trying to get my novel, Struggles of the Soul, up on Kindle, Dr. Kumar and his team at DMI saved my sanity!',
    quote2: null,
    quote3: null,
    cite: 'Hollis Arban',
    origin: 'HA Productions',
  },
  {
    quote1: 'What a joy it was to work with DMI…',
    quote2:
      'They were very responsive and responsible with my project and the quality of their work was excellent…',
    quote3: null,
    cite: 'Ken Beller and Heather Chase',
    origin: 'LTS Press',
  },
]

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     cover10: file(relativePath: { eq: "books-recent/cover10.jpg" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 300
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section id="who-we-are">
    <div className="container">
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <div>
            <div>
              <p>
                Simplicity, expertise, care to detail, and professionalism
                matters deeply to us and our clients recognize it in us. That is
                why 9 out of 10 people come back to us with more work.
              </p>
            </div>
            <div>
              <p>
                <strong>PAST CLIENTS </strong>
                <br />
                Penguin Random House
                <br />
                Inner Traditions
                <br />
                Magenta Press
                <br />
                Barcelona Publishers
                <br />
                University College London
                <br />
                Studies Weekly
                <br />
                Perfection Learning
                <br />
                Mango Languages
                <br />
                Connecticut Explored
                <br />
                Davis Publications
                <br />
                Scientific Driven Systems
                <br />
                Rhetor House Press
                <br />
                Mountain of Fire and Miracle Ministries
                <br />
                Straw Hat
                <br />
                Temple University
                <br />
                Mother of Light Publications
                <br />
                SeaCastle Films
                <br />
                The Deliberate Musician
                <br />
                HA Productions
                <br />
                LTS Press
              </p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
          <div>
            <div>
              <div>
                <div>&nbsp;</div>
                <div>
                  <p>
                    Founded in 2007, Digital Media Initiatives remained a
                    privately-held proprietorship firm. It has been incorporated
                    as a private limited company in October 2021 to be known as
                    "DMI Systems Pvt Ltd" or "DMI Systems PLC."
                  </p>
                  <p>
                    Over the years we have worked with very large publishing
                    houses as well as new-age indie publishers; individual
                    authors; schools, colleges, and universities;
                    non-governmental organizations, and many others who want to
                    leverage modern technology to further their cause.
                  </p>
                  <p>
                    Previously we focused on the changes in business created by
                    digitalization, especially in the publishing industry. As
                    industry boundaries shift and technologies evolve, our work,
                    offerings, and client base have expanded.
                  </p>
                </div>
                <div>
                  <h2>past work & who we are</h2>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <p>
                    We are a small team of seven researchers, strategists,
                    designers, writers, technologists, engineers and organizers,
                    and have over two decades of experience in the publishing,
                    digital media, new media, education, education technology
                    and creative domains.
                  </p>
                  <p>
                    We are happy to report that our small team has consistently
                    outperformed large companies and won clients, from Fortune
                    500 companies to celebrity authors.
                  </p>
                </div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
          <div>
            <div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
            <div>
              <div>
                <Carousel autoplay autoplaySpeed={5000} dots dotPosition="top">
                  {testimonials.map((testimonial) => (
                    <div>
                      <br />
                      <p>
                        <q>
                          <i>
                            {testimonial.quote1 && (
                              <Fragment>{testimonial.quote1}</Fragment>
                            )}
                            {testimonial.quote2 && (
                              <Fragment>
                                &nbsp;
                                <span className="dot">
                                  {testimonial.quote2}
                                </span>
                              </Fragment>
                            )}
                            {testimonial.quote3 && (
                              <Fragment>&nbsp;{testimonial.quote3}</Fragment>
                            )}
                          </i>
                        </q>
                        &nbsp;—&nbsp;
                        <strong>
                          {testimonial.cite}
                          {testimonial.origin && (
                            <Fragment>
                              ,&nbsp;
                              {testimonial.origin}
                            </Fragment>
                          )}
                        </strong>
                      </p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
