// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import CountUp from 'react-countup'
import { TextLoop } from 'react-text-loop-next'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {

//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**  */
class Tick extends React.PureComponent {
  /** standard constructor */
  constructor(props) {
    super(props)

    const heading1 = [
      'Books',
      'Publications',
      'Titles',
      'Tomes',
      'Volumes',
      'Treatise',
      'Works',
      'Opuses',
    ]
    const heading2 = [
      'dealt with',
      'managed',
      'tackled',
      'taken care of',
      'contended with',
      'given attention to',
      'seen to',
      'taken stock of',
      'handled',
      'confronted',
      'addressed',
      'grappled with',
    ]
    const heading = `
    ${heading1[Math.floor(Math.random() * heading1.length)]}
    ${heading2[Math.floor(Math.random() * heading2.length)]}
    as of
    `

    /**
     * state
     */
    this.state = {
      initCount: 700 * 350, // Count of pages as on 20'th Sept 2017 -- prevent FOFT
      totalBooks: 700,
      oldCount: undefined,
      updatedCount: undefined,
      heading,
    }

    this.update = this.update.bind(this)
    this.getToday = this.getToday.bind(this)
    this.daysSince = this.daysSince.bind(this)
    this.update = this.update.bind(this)
  }

  /**
   * componentDidMount - Invoked immediately after a component is mounted
   */
  componentDidMount() {
    this.update(true)
    setInterval(() => {
      this.update()
    }, 4000)
  }

  /**
   * daysSince
   */
  getToday() {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = `0${dd}`
    }
    if (mm < 10) {
      mm = `0${mm}`
    }
    today = `${mm}/${dd}/${yyyy}`
    return today
  }

  /**
   * daysSince
   */
  daysSince(date1, date2) {
    const dateA = new Date(date1)
    const dateB = new Date(date2)
    const timeDiff = Math.abs(dateB.getTime() - dateA.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return diffDays
  }

  /**
   * update
   */
  update(isInit) {
    if (isInit) {
      // How many pages have we converted?
      const daysSinceStart = this.daysSince('01/01/2007', this.getToday()) // We started on 1'st of Jan
      const avgWorkingDays = Math.round(
        Math.round(daysSinceStart * 0.5) * 0.235
      ) // We've worked about 50% of the time
      const avgBooksPerDay = 4 // We convert about 5 books a day
      const avgPapersPerBook = 187 // There are about 350 pages in a book
      const totalBooks = avgWorkingDays * avgBooksPerDay // Total books
      // const totalBooks = 732 // Total books
      const totalPages = totalBooks * avgPapersPerBook // Total pages
      this.setState({ initCount: totalPages, totalBooks })
    } else {
      const addPages = (4 * 350) / 6300 // We convert so many pages a day (4 books * 350 pages). We work for about 7 hours a day(25200 seconds). Because we update the count every 3 seconds there are (25200/4 = 6300 ticks).
      const updatedCount =
        (typeof this.state.updatedCount === 'undefined'
          ? this.state.initCount
          : this.state.updatedCount) + addPages
      this.setState({
        oldCount:
          typeof this.state.updatedCount === 'undefined'
            ? this.state.initCount
            : this.state.updatedCount,
        updatedCount,
      })
    }
  }

  /** standard renderer */
  render() {
    // const datetime = new Date().toLocaleString('en-US')
    // console.log(this.state.initCount, this.state.updatedCount)

    return (
      <Fragment>
        we have participated in the publishing of{' '}
        {this.state.totalBooks.toLocaleString()} books with about
        <CountUp
          start={
            this.state.updatedCount
              ? this.state.oldCount
              : this.state.initCount * 0.8
          }
          end={
            this.state.updatedCount
              ? this.state.updatedCount
              : this.state.initCount
          }
          duration={4}
          useEasing
          useGrouping
          separator=","
          decimal="."
          prefix=" "
          suffix=" pages"
        />
        <br />
        <TextLoop style={{ display: 'inline-block' }} interval={5000}>
          <span>in 20 international and 9 indic languages</span>
          <span>from more than 40 countries</span>
          <span>by about 232 publishers or authors</span>
        </TextLoop>
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Tick
