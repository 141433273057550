// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import Link from '../../link'
// import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section id="technology-but-why">
    <div className="container">
      <div className="steps">
        <div className="step">
          <p>
            <span>internet was magical in the 90s</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>we remade ourselves in it's image</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>now we wonder we wonder we wonder</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>does technology liberate or captivate?</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>what about happiness? are you happy?</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>do we now have more free time? for love?</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>does technology make it more more inclusive?</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>maybe, maybe not, maybe, maybe not, maybe</span>
          </p>
        </div>
        <div className="step">
          <p>
            <span>maybe we need a different way to do it. is there?</span>
          </p>
        </div>
      </div>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            The contemporary world faces a radical crisis today. Inequality,
            poverty, ecological degradation, human rights abuse, global warming,
            unsustainable resource depletion, illiteracy, lack of access to
            education, woefully inadequate health care and brutal conflicts
            continue to plague the world in undiminished ways.
          </p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            Our present crisis is a calling and it is for each one of us to
            respond. It is urgent that we move beyond economic sustainability to
            regenerative systems; expand diversity to include true social and
            economic inclusion, and broaden the definition of success to include
            others, including our global community and our planet.
          </p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            We, in our own little way, have endeavoured to create a business
            that has the possibility of a world that is regenerative, just, and
            prosperous. Maybe in the face of impending and inevitable
            catastropes it doesn't matter so much. Or maybe this, like millions
            elsewhere right now, express a new creation?
          </p>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <p>
            We experience fulfillment and pride that our services, which are
            used by curriculum materials publishers, academic publishers,
            education agencies and governmental and non-governmental
            organizations, has positively impacted tens – maybe hundreds – of
            thousands of learners, including many differently abled students.
          </p>
        </Col>
      </Row>
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
