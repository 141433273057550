// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import keys from 'lodash/keys'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'
import shuffle from 'lodash/shuffle'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Tick from '../../tick'
import '../../tick/style.less'

import Gallery from '../../gallery'
import '../../gallery/style.less'

// import Link from '../../link'
// import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    cover10: file(relativePath: { eq: "books-recent/cover10.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover1: file(relativePath: { eq: "books-recent/cover1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover2: file(relativePath: { eq: "books-recent/cover2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover3: file(relativePath: { eq: "books-recent/cover3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover4: file(relativePath: { eq: "books-recent/cover4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover5: file(relativePath: { eq: "books-recent/cover5.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover6: file(relativePath: { eq: "books-recent/cover6.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover7: file(relativePath: { eq: "books-recent/cover7.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover8: file(relativePath: { eq: "books-recent/cover8.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover9: file(relativePath: { eq: "books-recent/cover9.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover11: file(relativePath: { eq: "books-recent/cover11.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover12: file(relativePath: { eq: "books-recent/cover12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover13: file(relativePath: { eq: "books-recent/cover13.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover14: file(relativePath: { eq: "books-recent/cover14.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover15: file(relativePath: { eq: "books-recent/cover15.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover16: file(relativePath: { eq: "books-recent/cover16.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover17: file(relativePath: { eq: "books-recent/cover17.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover18: file(relativePath: { eq: "books-recent/cover18.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover19: file(relativePath: { eq: "books-recent/cover19.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover20: file(relativePath: { eq: "books-recent/cover20.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover21: file(relativePath: { eq: "books-recent/cover21.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover22: file(relativePath: { eq: "books-recent/cover22.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover24: file(relativePath: { eq: "books-recent/cover24.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover25: file(relativePath: { eq: "books-recent/cover25.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover26: file(relativePath: { eq: "books-recent/cover26.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover27: file(relativePath: { eq: "books-recent/cover27.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover28: file(relativePath: { eq: "books-recent/cover28.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover29: file(relativePath: { eq: "books-recent/cover29.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover30: file(relativePath: { eq: "books-recent/cover30.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover31: file(relativePath: { eq: "books-recent/cover31.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover32: file(relativePath: { eq: "books-recent/cover32.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover33: file(relativePath: { eq: "books-recent/cover33.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover34: file(relativePath: { eq: "books-recent/cover34.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover35: file(relativePath: { eq: "books-recent/cover35.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover36: file(relativePath: { eq: "books-recent/cover36.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover37: file(relativePath: { eq: "books-recent/cover37.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover38: file(relativePath: { eq: "books-recent/cover38.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover39: file(relativePath: { eq: "books-recent/cover39.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover40: file(relativePath: { eq: "books-recent/cover40.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover41: file(relativePath: { eq: "books-recent/cover41.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover42: file(relativePath: { eq: "books-recent/cover42.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover43: file(relativePath: { eq: "books-recent/cover43.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover44: file(relativePath: { eq: "books-recent/cover44.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cover45: file(relativePath: { eq: "books-recent/cover45.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section id="what-we-have-done-so-far">
    <div className="container">
      <h2>Recently Published</h2>
      <div className="carrier">
        <h3>
          <Tick />
        </h3>
        <StaticQuery
          query={query}
          render={(data) => {
            const imageKeys = keys(data)
            let images = []

            map(imageKeys, (key) => {
              if (startsWith(key, 'cover') === true) {
                images.push(data[key])
              }
            })

            images = shuffle(images)

            return <Gallery images={images} targetRowHeight={180} />
          }}
        />
      </div>
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
