// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isUndefined from 'lodash/isUndefined'
import debounce from 'lodash/debounce'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import 'intersection-observer'
import { InView } from 'react-intersection-observer'

import { Flipper, Flipped } from 'react-flip-toolkit'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import onAppear from './on-appear'
import onExit from './on-exit'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** FeatureSet */
class FeatureSet extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.sectionContentRefs = {}

    this.state = {
      lastChange: new Date().getTime(),
      activeIndex: 1,
    }

    this.setActiveIndex = debounce(this.setActiveIndex.bind(this), 100)
    this.onNavClick = this.onNavClick.bind(this)
    this.refSetter = this.refSetter.bind(this)
    this.setColor = this.setColor.bind(this)
  }

  /** after mount */
  componentDidMount() {
    if (!isUndefined(document)) {
      const { data } = this.props

      if (data.length > 0) {
        this.setColor()
      }
    }
  }

  /** after update */
  componentDidUpdate(prevProps, prevState) {
    if (!isUndefined(document)) {
      const { data: currentData } = this.props
      const { data: lastData } = prevProps

      if (currentData.length > 0) {
        const { activeIndex: currentActiveIndex } = this.state
        const { activeIndex: lastActiveIndex } = prevState

        if (
          currentActiveIndex !== lastActiveIndex ||
          currentData.length !== lastData.length
        ) {
          this.setColor()
        }
      }
    }
  }

  /**
   * [onNavClick description]
   * @param  {[type]} point [description]
   * @return {[type]}       [description]
   */
  onNavClick(point) {
    return (e) => {
      e.preventDefault()
      console.log(point, this.sectionContentRefs)
      this.sectionContentRefs[point] &&
        this.sectionContentRefs[point].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
    }
  }

  /** [setColor description] */
  setColor() {
    if (!isUndefined(document)) {
      const { data } = this.props

      if (data.length > 0) {
        const { activeIndex } = this.state
        const { color } = data[activeIndex]

        const featurePointsParent = document.getElementById('featureSetWrapper')
        if (featurePointsParent) {
          if (featurePointsParent.style.backgroundColor !== color) {
            featurePointsParent.setAttribute(
              'style',
              `background-color: ${color}`
            )
          }
        }
      }
    }
  }

  /**
   * [setActiveIndex description]
   * @param {[type]} point [description]
   */
  setActiveIndex(index) {
    const { activeIndex } = this.state
    if (activeIndex !== index) {
      this.setState(
        {
          activeIndex: index,
          lastChange: new Date().getTime(),
        },
        () => {
          this.setColor()
        }
      )
    }
  }

  /**
   * [refSetter description]
   * @param  {[type]}   point    [description]
   * @param  {Function} callback [description]
   * @return {[type]}            [description]
   */
  refSetter(index, callback) {
    return (node) => {
      this.sectionContentRefs[index] = node
      callback(node)
    }
  }

  /** standard renderer */
  render() {
    const { activeIndex } = this.state
    const { data, threshold = 0.9 } = this.props

    return (
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
        className="feature-set"
        justify="space-between"
      >
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
          <div className={`active-${activeIndex}`}>
            <p className="as-h2">{data[activeIndex].title}</p>
            <p>{data[activeIndex].subTitle}</p>
          </div>
          <ul>
            <Flipper flipKey={data.map((item) => item.key).join('')}>
              {data.map((item, i) => (
                <Flipped
                  key={item.key}
                  flipId={item.key}
                  onAppear={onAppear}
                  onExit={onExit}
                >
                  <li className={activeIndex === i ? 'active' : ''}>
                    <Link to={`/#${item.hash}`}>{item.title}</Link>
                  </li>
                </Flipped>
              ))}
            </Flipper>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
          {data.map(({ feature, hash, key }, i) => (
            <InView
              threshold={threshold}
              onChange={(inView, entry) => {
                this.setActiveIndex(i)
              }}
              key={key}
              as="article"
              id={hash}
              className={activeIndex === i ? 'active' : ''}
            >
              {feature}
            </InView>
          ))}
        </Col>
      </Row>
    )
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default FeatureSet
