// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../../../methods/small-key'

import FeatureSet from '../../feature-set'
import '../../feature-set/style.less'

import Link from '../../link'
import '../../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Section */
const Section = () => (
  <section id="offerings">
    <div className="container">
      <FeatureSet
        data={[
          {
            key: smallKey(),
            hash: 'publishing',
            title: 'Digital Publishing',
            subTitle:
              "Technology has opened new doors to endless opportunities for publishers. Today, digital content is more important than print. DMI is at home in such a world and has two decade's worth of experience to put you ahead of the game.",
            feature: (
              <Fragment>
                <div>
                  <h2>ePubNow! Digital Publishing Services</h2>
                  <p>
                    Transform your manuscript into a high-quality eBook that can
                    be read on smartphones, tablets and popular eReader
                    including the Amazon Kindle, Apple iPad, Barnes & Noble
                    Nook. Key features:
                  </p>
                  <h3>From Print to Digital Books</h3>
                  <p>
                    Convert your Word, InDesign or PDF manuscript into superior
                    quality electronic books that run on all modern devices.
                    Output formats include - free-flowing EPUB and Kindle;
                    Fixed-format EPUB and Kindle (KF8), DAISY Digital Talking
                    Book, Android Apps, and Apple iOS Apps. We have successfully
                    produced in over 90 languages.
                  </p>
                  <h3>Enhanced eBooks</h3>
                  <p>
                    Embed rich and interactive multimedia in your book. Work
                    with GIFs, video, audio, or add widgets like timers, quizzes
                    and more.
                  </p>
                  <h3>Future Proof Publisher Workflow</h3>
                  <p>
                    DocBook XML is an interim format produced by our system.
                    This is a future-proof format that can easily be edited and
                    revised by publishers. Multiple eBook formats can be rapidly
                    produced after revision, saving a lot of effort, time and
                    costs for the publisher.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'drm',
            title: 'Digital Rights Management',
            subTitle:
              "Technology has opened new doors to endless opportunities for publishers. Today, digital content is more important than print. DMI is at home in such a world and has two decade's worth of experience to put you ahead of the game.",
            feature: (
              <Fragment>
                <div>
                  <h2>
                    Digital Media Reserve: Platform for Secure Distribution
                  </h2>
                  <p>
                    Digital Media Reserve is an implementation of the Adobe
                    Content Server which allows digital rights management (DRM)
                    for e-books. It is designed to protect and distribute Adobe
                    eBooks in PDF or EPUB format through Adobe Digital Editions.
                    Digital Media Reserve has developed a comprehensive Internet
                    based infrastructure to distribute your DRM enabled eBooks
                    globally. Key partners:
                  </p>
                  <h3>Adobe</h3>
                  <p>
                    Adobe Content Server 6.5 (ACS) helps us securely distribute
                    and monetize your eBooks and PDF content. Offers great
                    ease-of-use as the books your customers purchase appear
                    automatically across all their eBook readers. Open new
                    markets with flexible business models for subscription and
                    text book distribution
                  </p>
                  <h3>Datalogics</h3>
                  <p>
                    Datalogics focuses on providing technologies to software
                    developers and enabling them to bring their products to
                    market faster and with superior eBook and PDF capabilities.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'dmm',
            title: 'Digital Media Management',
            subTitle:
              'The ONIX-for-Books is an XML-based standard for rich book metadata, providing a consistent way for publishers, retailers and their supply chain partners to communicate rich information about their products. It is expressly designed to be used globally, and is not limited to any one language or the characteristics of a specific national book trade.',
            feature: (
              <Fragment>
                <div>
                  <h2>ONIX Master: Platform for Title Metadata Management</h2>
                  <h3>Online Title Data Management Solution</h3>
                  <p>
                    ONIX Master is a powerful yet easy tool to manage rich title
                    data into the supply chain in a standard form, to
                    wholesalers and distributors, to larger retailers, to data
                    aggregators, and to affiliate companies. ONIX Master greatly
                    reduces support costs, as publishers no longer need to
                    provide data in so many unique formats. We ensure that a
                    single data feed can be made suitable for all of a
                    publisher’s supply chain partners.
                  </p>
                  <h3>Advance Information Sheets</h3>
                  <p>
                    ONIX Master has helped to stimulate the introduction of
                    better internal information systems, capable of bringing
                    together all the ‘metadata’ needed for the description and
                    promotion of new and backlist titles. ONIX Master also
                    ensures that the same core ONIX data can also be used to
                    produce advance information sheets, catalogs and other
                    promotional material, to feed publisher websites, and to
                    meet the needs of the wider supply chain.
                  </p>
                  <h3>Improved Discoverability</h3>
                  <p>
                    ONIX for Books means more efficient and rapid loading of
                    up-to-date product information into internal or
                    customer-facing systems, with less need for manual
                    intervention and much lower risk of error. ONIX Master
                    encourages the overall improvement of the data available
                    throughout the supply chain and improves title
                    discoverability resulting in more sales for publishers.
                  </p>
                  <br />
                  <p>
                    The Book Industry Study Group, Inc. (BISG)’s BISAC (Book
                    Industry Standards And Communications)’s Metadata Committee
                    has worked on the Best Practices for implementing ONIX.
                    Digital Media Initiatives is an ONIX Technical Service
                    Provider listed with BISG.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'accessibility',
            title: 'Independent Publishing',
            subTitle:
              'We offer a broad range of accessibility and remediation services including NIMAS conversion (with MathML 3.0), Tagged PDF/UA remediation as per the Web Content Accessibility Guidelines (WCAG) 2.x and Section 508 specifications.',
            feature: (
              <Fragment>
                <div>
                  <h2>Antrik Express: Platform for Indie Publishers</h2>
                  <h3>Robust Backend</h3>
                  <p>
                    ONIX Master and Digital Media Reserve provide a solid
                    backend to Antrik Express, the online retail platform for
                    eBooks in EPUB and PDF formats. Antrik ExPress is a retail
                    system that is integrated with Digital Media Reserve. EPUB
                    or PDF/A formatted ebooks are uploaded to the central
                    repository, protected under DRM and are allowed to be sold
                    through multiple channels.
                  </p>
                  <h3>Multiple Retail Models</h3>
                  <p>
                    Antrik ExPress is also an express publishing workflow for
                    self-publishers. Authors and publishers are empowered the
                    world over to publish, sell, track sales and promote
                    themselves with powerful tools and with complete
                    transparency. Antrik e-Library is for schools and academic
                    institutions who could license the academic content to the
                    institution forever or for a set time. Licensing is
                    negotiated by the academic publisher with an institution on
                    their own. Content is delivered under DRM protection. Antrik
                    ebooks rental system is a business model where any published
                    content can be licensed for a smaller period of time for the
                    duration of the rent, and pricing can be set accordingly.
                    Content is delivered under DRM protection.
                  </p>
                  <h3>Integrated eCommerce</h3>
                  <p>
                    Antrik ExPress has an in-built eCommerce engine which can
                    easily be integrated with multiple payment gateways,
                    including PayPal.
                  </p>
                  <h3>Integrated eCommerce</h3>
                  <p>
                    Antrik ExPress platform is already being used as white
                    labeled eBookstores. This is a seamless integration with a
                    publisher catalog available in ONIX for Books XML format.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'accessibility',
            title: 'Accessible Publishing',
            subTitle:
              'We offer a broad range of accessibility and remediation services including NIMAS conversion (with MathML 3.0), Tagged PDF/UA remediation as per the Web Content Accessibility Guidelines (WCAG) 2.x and Section 508 specifications.',
            feature: (
              <Fragment>
                <div>
                  <h2>Accessible Publishing Service</h2>
                  <p>
                    We offer the conversion of materials into accessible
                    formats, such as braille, audio and large print, that make
                    them available for persons who are blind, visually impaired
                    or otherwise print disabled.
                  </p>
                </div>
                <div>
                  <h2>NIMAS Conversion Service</h2>
                  <p>
                    We offer an end-to-end production service of curriculum
                    materials into NIMAS (National Instructional Materials
                    Accessibility Standard) fileset for NIMAC Repository.
                  </p>
                  <h3>MathML</h3>
                  <p>
                    Convert scientific notations, mathematical expressions and
                    complex equations to MathML XML and Spoken Maths descriptors
                    for the visually disabled.
                  </p>
                  <h3>Accessible PDF and Source</h3>
                  <p>
                    We ensures your PDF documents meet today’s leading
                    accessibility standards, such as Web Content Accessibility
                    Guidelines (WCAG) 2.0 Level AA, PDF/UA. Our process ensures
                    all documents are accurately tagged for the end user. Our
                    goal is to provide a consistent, fully accessible document
                    that accurately conveys all relevant information. We work
                    around your source documents - Adobe InDesign, Microsoft
                    Office, OpenOffice Writer, and HTML to align with your
                    accessibility goals. We also offer extensive services for
                    curating Digital Talking Books conforming to DAISY 3, Print
                    & Digital Braille.
                  </p>
                </div>
                <div>
                  <h2>Document Remediation Service</h2>
                  <h3>Accessible Google Docs and Google Slides</h3>
                  <p>
                    Publishers are encouraged to adopt highly-accessible layouts
                    to ensure their documents and presentation have the correct
                    structure for ADA Compliance. We help you make your Google
                    documents or presentations more accessible.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'education',
            title: 'Education Technology',
            subTitle: 'education publishing and technology platform',
            feature: (
              <Fragment>
                <div>
                  <h2>Accessible Publishing Service</h2>
                  <p>
                    In a world of increasing uncertainty, complexity and
                    contradiction we need to better understand and anticipate
                    the changing learning landscape. In doing so, we need to
                    re-contextualize the foundational principles of a humanistic
                    approach to education governance and make a collective
                    effort towards the future of education. Revisiting the
                    purpose of education and the organization of learning in a
                    changing global context, it is a call for both public policy
                    dialogue and forward-looking research on learning systems in
                    the 21st century. (
                    <Link to="https://unesdoc.unesco.org/ark:/48223/pf0000232555_eng">
                      Rethinking Education: Towards a global common good?, 2015
                    </Link>
                    ) is the fruit of the work of a Senior Experts' Group
                    established by the Director-General of UNESCO to rethink
                    education in a world of rapid transformation.
                  </p>
                </div>
                <div>
                  <h2>Competency Systems</h2>
                  <p>
                    The Competency Systems ® is the next-generation
                    teaching-learning platform. The platform has seven key
                    features essential for creating a teaching-learning
                    community implementing competency-based education:
                  </p>
                  <h3>Learner-Centered Education</h3>
                  <p>
                    Learners are empowered daily to make important decisions
                    about their learning experiences, how they will create and
                    apply knowledge, and how they will demonstrate their
                    learning.
                  </p>
                  <h3>Assessment Authoring and Learning Delivery System</h3>
                  <p>
                    Offers meaningful assessments empowering learning experience
                    for students that yields timely, relevant, and actionable
                    evidence.
                  </p>
                  <h3>Powerful Feedback System</h3>
                  <p>
                    Learners receive timely, differentiated support based on
                    their individual learning needs.
                  </p>
                  <h3>Meaningful Analytics</h3>
                  <p>Learners progress based on evidence of mastery.</p>
                  <h3>Learning Pathways</h3>
                  <p>
                    Learners learn actively using different pathways and varied
                    pacing.
                  </p>
                  <h3>Equity in Education</h3>
                  <p>
                    Offers strategies to ensure equity for all students of
                    schools and education systems.
                  </p>
                  <h3>Powerful Reference Framework Designer</h3>
                  <p>
                    Offers an integrated framework defining rigorous, common
                    expectations for learning (knowledge, skills, and
                    dispositions) that are explicit, transparent, measurable,
                    and transferable.
                  </p>
                  <br />
                  <p>
                    An innovative school implements all seven elements mentioned
                    above while we offer the school systems a comprehensive
                    Next-Generation Digital Learning Environment (NGDLE) and
                    consultancy for developing policies and culture that support
                    every student in developing essential knowledge, skills, and
                    dispositions.
                  </p>
                </div>
              </Fragment>
            ),
          },
          {
            key: smallKey(),
            hash: 'social-venture',
            title: 'Social Outreach',
            subTitle: 'technology for good',
            feature: (
              <Fragment>
                <div>
                  <h2>Serving Visually Challenged Fraternity</h2>
                  <p>
                    We serve by supporting producing and distributing
                    instructional materials in print-braille, e-braille, and
                    digital talking books.
                  </p>
                </div>
                <div>
                  <h2>Fostering 21st-Century Skills and Literacy</h2>
                  <p>
                    Our edtech platform is currently being tested by rural
                    schools. We are committed to making our offerings low-cost,
                    but delivering state-of-the-art multilingual content and
                    learning pathways for fostering 21st-century skills and
                    literacy at primary, secondary, and tertiary level
                    education.
                  </p>
                </div>
              </Fragment>
            ),
          },
        ]}
      />
    </div>
  </section>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Section
