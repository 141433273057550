// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Waypoint } from 'react-waypoint'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import '../components/sections/style.less'

import LandingSection from '../components/sections/landing'
import '../components/sections/landing/style.less'

import WhatWeHaveDoneSoFar from '../components/sections/what-we-have-done-so-far'
import '../components/sections/what-we-have-done-so-far/style.less'

import Summary from '../components/sections/summary'
import '../components/sections/summary/style.less'

import Offerings from '../components/sections/offerings'
import '../components/sections/offerings/style.less'

import WhoWeAre from '../components/sections/who-we-are'
import '../components/sections/who-we-are/style.less'

import TechnologyButWhy from '../components/sections/technology-but-why'
import '../components/sections/technology-but-why/style.less'

import { updateHeaderState } from '../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract:
    'Digital Media Initiatives is a design and technology house from India. Our small and international team has come together for a world that is regenerative, just, and prosperous for everyone.',
  breadcrumbs: [{ title: 'Homepage', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const { updateHeaderState, headerState } = props

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <LandingSection />
      <Summary />
      <WhoWeAre />
      <WhatWeHaveDoneSoFar />
      <TechnologyButWhy />
      {/*<Waypoint
        bottomOffset="-200px"
        fireOnRapidScroll={false}
        onEnter={() => updateHeaderState({ ...headerState, active: true })}
        onLeave={() => updateHeaderState({ ...headerState, active: false })}
        scrollableAncestor={window}
      >
        <div>
          <WhoWeAre />
          <WhatWeHaveDoneSoFar />
          <TechnologyButWhy />
        </div>
      </Waypoint>*/}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  }),
  (dispatch) => ({
    updateHeaderState(payload) {
      dispatch(updateHeaderState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedPage
